import axios from 'axios';
import { ListOption } from './ListOptions';
import router from '@/router/index';
import { yesno, information } from '@/utility/messages';
import { Classification, ClassificationItem } from '@/utility/Classification';

// --- 配信状態
export const publicStTexts = ['配信停止', '配信中', '下書保存', '差し戻し', '未承認'];
export const publicStClasses = ['st-stop', 'st-public', 'st-draft', 'st-remand', 'st-req-approval'];

export const photoSearchCondtionName = 'PhotoSearchCond';
export const photoCrtPageName = 'PhotoCrtPage';

//  配信状態
export const PublicSt = {
  stop: 0, //< 配信停止
  public: 1, //< 配信
  draft: 2, //< 下書き保存
  remand: 3, //< 差し戻し
  reqApproval: 4, //< 承認依頼
} as const;

//  --- 写真種類
const PhotoType = {
  sample: 0, //< pcare.jpがover write 有償画像用
  thumb: 1, //< 小さい解像度
  jpeg: 2, //< 元画像をjpeg化
  original: 3, //< 元画像
} as const;

// --- 配信種類
export const ricSignTexts = ['未設定', 'RIC', '有償'];
export const ricSignClasses = ['none', 'ric', 'paid'];

//  --- DTO
//  --- 一覧画面
interface ListBasicItem {
  bases: BaseItem[];
  childs: ChildItem[];
  editableUser: boolean;
}

interface BaseItem {
  bcode: string;
  bntiny: string;
}

interface ChildItem {
  ccode: number;
  cname: string;
  ckana: string;
  className: string;
  photoright: number;
}

export interface PhotoCondition {
  upStartDate: string;
  upEndDate: string;
  typeNone: boolean;
  typeRic: boolean;
  typePaid: boolean;
  className: string;
  cname: string;
  stStop: boolean;
  stPublic: boolean;
  stDraft: boolean;
  stRemand: boolean;
  stReqApproval: boolean;
}

//  検索時
interface SearchResultItem {
  pageLength: number;
  crtPage: number;
  winStartPage: number;
  winPageLength: number;
  rows: SearchRowItem[];
}

interface SearchRowItem {
  id: number;
  no: number;
  ricSign: number;
  cnames: string[];
  pnames: string[];
  rcomment: string;
  icomment: string;
  ccomment: string;

  //  画像
  imgPath: string | undefined; //< thumb
  imgFname: string | undefined;
  imgUrl: string | undefined;

  //  アップロード
  uploadDate: string | undefined;
  uploadScode: number | undefined;
  uploadSname: string | undefined;

  //  発信
  publicStatus: number;
  publicDate: string | undefined;
  publicScode: number | undefined;
  publicSname: string | undefined;

  goodCount: number;

  // DLチェック
  check: boolean;
}

export type SearchResult = SearchResultItem;
export type SearchRow = SearchRowItem;

export interface ListPageData {
  //  基本データ
  baseOpts: ListOption<string>[];
  childNameOpts: ListOption<string>[];
  childClassOpts: ListOption<string>[];
  editableUser: boolean;

  //  検索条件
  condtion: PhotoCondition;

  //  検索結果
  searchResult: SearchResult;
}

// --- 新規・編集画面
interface EditBasicItem {
  pageAuth: number;
  classifications: ClassificationItem[];
  childs: ChildItem[];
}

interface PhotoEditItem {
  no: number;
  ricSign: 0 | 1 | 2; //< 0...未設定 1...ric 2...有償

  //  有償データ
  price: number;
  pcomment: string;

  // RIC
  rcomment: string;
  icomment: string;
  ccomment: string;

  // 被写体
  ccodes: number[];

  // 画像
  imgPath: string;
  imgFname: string;

  file: File | null;

  shootDate: string; //< 撮影日
  shootArea: string; //< 撮影場所
  photoSection: string; //< 区分
  photoType: string; //< 種別

  // アップロード
  uploadDate: string | Date | undefined;
  uploadScode: number | undefined;

  // 発信
  publicStatus: number;
  publicDate: string | Date | undefined;
  publicScode: number | undefined;
}

// IMG
interface UploadImgResultItem {
  success: boolean;
  fileName: string;
  messages: string[];
  imgFname: string;
  imgPath: string;
}

// --- Control
export interface EditPageData {
  //  基本データ
  pageAuth: number;
  childs: ChildItem[];
  classifications: Classification[];
  imgUrl: string;

  photo: PhotoEdit;
  orgJson: string;
}

type PhotoEdit = PhotoEditItem;

interface PageAuth {
  edit: 0 | 1 | 2; //< 0...非表示 1...表示 2...操作可
  reqApprovalBtn: 0 | 1 | 2;
  draftBtn: 0 | 1 | 2;
  deleteBtn: 0 | 1 | 2;
  remandBtn: 0 | 1 | 2;
  publicBtn: 0 | 1 | 2;
  stopBtn: 0 | 1 | 2;
}

//  閲覧のみ
export const pageAuthNone: PageAuth = {
  edit: 1,
  reqApprovalBtn: 0,
  draftBtn: 0,
  deleteBtn: 0,
  remandBtn: 0,
  publicBtn: 0,
  stopBtn: 0,
};

//  入力可
const pageAuthInput: PageAuth = {
  edit: 2,
  reqApprovalBtn: 2,
  draftBtn: 2,
  deleteBtn: 2,
  remandBtn: 1,
  publicBtn: 1,
  stopBtn: 1,
};

//  承認可
const pageAuthApproval: PageAuth = {
  edit: 2,
  reqApprovalBtn: 2,
  draftBtn: 2,
  deleteBtn: 2,
  remandBtn: 2,
  publicBtn: 2,
  stopBtn: 2,
};

export const pageAuthes: PageAuth[][] = [
  //  配信停止(0)
  [
    pageAuthNone, //  他拠点従事者(0)
    pageAuthInput, //  自拠点一般従事者(1)
    { ...pageAuthApproval, reqApprovalBtn: 1, stopBtn: 1 }, //  自拠点拠点長(2)
    { ...pageAuthApproval, reqApprovalBtn: 1, stopBtn: 1 }, //  マネージャー 本部従事者(3)
  ],
  //  配信中(1)
  [
    pageAuthNone, //  他拠点従事者(0)
    pageAuthNone, //  自拠点一般従事者(1)
    { ...pageAuthApproval, reqApprovalBtn: 1, publicBtn: 2 }, //  自拠点拠点長(2)
    { ...pageAuthApproval, reqApprovalBtn: 1, publicBtn: 2 }, //  マネージャー 本部従事者(3)
  ],
  //  下書保存(2)
  [
    pageAuthNone, //  他拠点従事者(0)
    pageAuthInput, //  自拠点一般従事者(1)
    pageAuthApproval, //  自拠点拠点長(2)
    pageAuthApproval, //  マネージャー 本部従事者(3)
  ],
  //  差し戻し(3)
  [
    pageAuthNone, //  他拠点従事者(0)
    pageAuthInput, //  自拠点一般従事者(1)
    { ...pageAuthApproval, reqApprovalBtn: 1, remandBtn: 1, stopBtn: 1 }, //  自拠点拠点長(2)
    { ...pageAuthApproval, reqApprovalBtn: 1, remandBtn: 1, stopBtn: 1 }, //  マネージャー 本部従事者(3)
  ],
  //  未承認(4)
  [
    pageAuthNone, //  他拠点従事者(0)
    pageAuthNone, //  自拠点一般従事者(1)
    { ...pageAuthApproval, reqApprovalBtn: 1, stopBtn: 1 }, //  自拠点拠点長(2)
    { ...pageAuthApproval, reqApprovalBtn: 1, stopBtn: 1 }, //  マネージャー 本部従事者(3)
  ],
];

export const userTypes = ['他拠点従事者(0)', '自拠点一般従事者(1)', '自拠点拠点長(2)', 'マネージャー 本部従事者(3)'];

// --- 基本データ
export async function loadListBasic(data: ListPageData, bcode: string) {
  axios.get<ListBasicItem>(`/api/MasterPhoto/listbasic/${bcode}`).then(response => {
    data.baseOpts = response.data.bases.map(v => {
      const res: ListOption<string> = {
        value: v.bcode,
        text: v.bntiny,
      };
      return res;
    });

    const childs = response.data.childs;
    //  クラス
    data.childClassOpts = [...new Set(childs.map(v => v.className))].sort().map(v => {
      const res: ListOption<string> = {
        value: v,
        text: !v.length ? 'クラス名なし' : v,
      };
      return res;
    });
    const noneClassOpt: ListOption<string> = {
      value: 'none',
      text: 'クラス選択なし',
    };
    data.childClassOpts.unshift(noneClassOpt);

    //  児童
    data.childNameOpts = childs
      .map(v => v.ckana)
      .sort()
      .map(v => {
        const res: ListOption<string> = {
          value: v,
          text: v,
        };
        return res;
      });
    const noneChildOpt: ListOption<string> = {
      value: 'none',
      text: '児童選択なし',
    };
    data.childNameOpts.unshift(noneChildOpt);

    data.editableUser = response.data.editableUser;
  });
}

//  --- 一覧
export function initListPageData(): ListPageData {
  const res: ListPageData = {
    baseOpts: [],
    childNameOpts: [],
    childClassOpts: [],
    editableUser: false,
    condtion: initCondition(),
    searchResult: {
      pageLength: 1,
      crtPage: 0,
      winStartPage: 0,
      winPageLength: 1,
      rows: [],
    },
  };
  return res;
}

export function initCondition(): PhotoCondition {
  const cond: PhotoCondition = {
    upStartDate: '',
    upEndDate: '',
    typeNone: false,
    typeRic: false,
    typePaid: false,
    className: 'none',
    cname: 'none',
    stStop: false,
    stPublic: false,
    stDraft: false,
    stRemand: false,
    stReqApproval: false,
  };
  return cond;
}

export async function loadPhotos(bcode: string, condition: PhotoCondition, crtPage: number): Promise<SearchResultItem> {
  const response = await axios.get<SearchResultItem>(`/api/MasterPhoto/search/${bcode}`, {
    params: {
      ...condition,
      page: crtPage,
    },
  });
  const data = response.data;
  const rows = data.rows.map(v => {
    const res: SearchRow = {
      ...v,
      check: false,
    };
    return res;
  });

  const item: SearchResultItem = {
    pageLength: data.pageLength,
    crtPage: data.crtPage,
    winStartPage: data.winStartPage,
    winPageLength: data.winPageLength,
    rows: rows,
  };
  return Promise.resolve(item);
}

export async function loadThumbs(photoRows: SearchRow[]) {
  photoRows.filter(v => !!v.imgPath && !!v.imgFname).forEach(v => downloadThumb(v));
}

async function downloadThumb(photoRow: SearchRow): Promise<void> {
  const result = await axios.post<Blob>('/api/MasterPhoto/exportimg', '', {
    params: {
      path: photoRow.imgPath,
      fname: photoRow.imgFname,
      type: PhotoType.thumb,
    },
    responseType: 'blob',
  });
  const blob = new Blob([result.data], { type: result.data.type });
  photoRow.imgUrl = URL.createObjectURL(blob);
}

//  --- 編集
export function initEditPageData(): EditPageData {
  const data: EditPageData = {
    pageAuth: 0,
    classifications: [],
    childs: [],
    imgUrl: '',
    photo: {
      no: 0,
      ricSign: 1,

      // 有償データ
      price: 0,
      pcomment: '',

      // RIC
      rcomment: '',
      icomment: '',
      ccomment: '',

      ccodes: [],

      imgPath: '',
      imgFname: '',

      file: null,

      shootDate: '-',
      shootArea: '',
      photoSection: '',
      photoType: '',

      //  アップロード
      uploadDate: undefined,
      uploadScode: undefined,

      //  発信
      publicStatus: 0,
      publicDate: undefined,
      publicScode: undefined,
    },
    orgJson: '',
  };
  return data;
}

export const photoSectionKcode = '写真区分';
export const photoTypeKcode = '写真種類';

export async function loadEditBasic(data: EditPageData, bcode: string) {
  axios.get<EditBasicItem>(`/api/MasterPhoto/editbasic/${bcode}`).then(response => {
    data.pageAuth = response.data.pageAuth;
    data.classifications = [...response.data.classifications];
    data.childs = [...response.data.childs];
  });
}

export async function loadPhoto(data: EditPageData, no: number) {
  axios.get<PhotoEditItem>(`/api/MasterPhoto/single/${no}`).then(response => {
    const item = response.data;
    data.photo = { ...item };
    data.imgUrl = '';
    data.orgJson = toPhotoJson(data.photo);
    if (!!data.photo.imgPath?.length && !!data.photo.imgFname?.length) {
      downloadJpegImg(data);
    }
  });
}

// 新規保存
export async function addPhoto(data: EditPageData, publicSt: number) {
  data.photo.publicStatus = publicSt;

  //  追加
  await axios.post(`/api/MasterPhoto/`, data.photo);

  data.orgJson = toPhotoJson(data.photo);
  router.push('/admin/master/photo');
}

//  更新
export async function updatePhoto(data: EditPageData, publicSt: number, bcode: string) {
  if ([PublicSt.draft, PublicSt.public, PublicSt.reqApproval].some(v => v === publicSt)) {
    const selCcoces = data.photo.ccodes;
    const selChs = data.childs.filter(v => v.photoright > 0 && selCcoces.some(x => x === v.ccode));

    const cnames = selChs.map(v => v.cname).join(', ');
    const act = ['', '公開', '下書き保存', '', '承認依頼'][publicSt];
    if (selChs.length > 0 && !(await yesno(`肖像権が設定されている児童が選択されています。\n${cnames}\n ${act}しますか？`))) {
      return;
    }
  }

  data.photo.publicStatus = publicSt;
  if (data.photo.file === null || data.photo.file === undefined) {
    await axios.put(`/api/MasterPhoto/single/${data.photo.no}`, data.photo);
  } else {
    const form = new FormData();
    form.append('file', data.photo.file as File);

    const photoItem = {
      ...data.photo,
      uploadDate: new Date().toJSON(),
    };

    const params = {
      lastModified: data.photo.file?.lastModified,
      photoJson: JSON.stringify(photoItem),
      bcode: bcode,
    };

    const response = await axios.post<UploadImgResultItem>('/api/MasterPhoto/update-image', form, {
      params: params,
    });

    if (!response.data.success) {
      await information('写真のアップロードに失敗しました');
    }
  }

  data.orgJson = toPhotoJson(data.photo);
  router.push('/admin/master/photo');
}

//  削除
export async function deletePhoto(data: EditPageData) {
  const res = await yesno(`写真を削除しますか?`, 'width: auto');
  if (!res) return;

  const no = data.photo.no;
  await axios.delete(`/api/MasterPhoto/single/${no}`);

  data.orgJson = toPhotoJson(data.photo);
  router.push('/admin/master/photo');
}

//  変更の可能性が高いため、このメソッドに統一
export function toPhotoJson(photo: PhotoEditItem): string {
  const temp = {
    ...photo,
  };
  // delete temp.pdfFile;

  return JSON.stringify(temp);
}

// ---- validation

//  全体
export function isValid(photo: PhotoEdit): boolean {
  return (
    isValidChilds(photo) &&
    ((photo.ricSign === 1 && isValidRcomment(photo) && isValidIcomment(photo) && isValidCcomment(photo)) ||
      (photo.ricSign === 2 && isValidPcomment(photo)))
  );
}

//  児童設定
export function isValidChilds(photo: PhotoEdit): boolean {
  return (photo.ricSign === 1 && photo.ccodes.length === 1) || (photo.ricSign === 2 && photo.ccodes.length > 0);
}

//  Rコメント
export function isValidRcomment(photo: PhotoEdit) {
  return photo.rcomment.replaceAll(' ', '').length > 0;
}

//  Iコメント
export function isValidIcomment(photo: PhotoEdit) {
  return photo.icomment.replaceAll(' ', '').length > 0;
}

//  Cコメント
export function isValidCcomment(photo: PhotoEdit) {
  return photo.ccomment.replaceAll(' ', '').length > 0;
}

//  有償コメント
export function isValidPcomment(photo: PhotoEdit) {
  return photo.pcomment.replaceAll(' ', '').length > 0;
}

// ---

async function downloadJpegImg(data: EditPageData): Promise<void> {
  const result = await axios.post<Blob>('/api/MasterPhoto/exportimg', '', {
    params: {
      path: data.photo.imgPath,
      fname: data.photo.imgFname,
      type: PhotoType.jpeg,
    },
    responseType: 'blob',
  });
  const blob = new Blob([result.data], { type: result.data.type });
  data.imgUrl = URL.createObjectURL(blob);
}

//  radioボタン
export const ricSignOpts: ListOption<number>[] = [
  {
    value: 2,
    text: '有償ダウンロード',
  },
  {
    value: 1,
    text: 'RICダイアリー',
  },
];

//  写真に格納された児童コードから児童名一覧を取得 ※ data.childsがソート済みを前提
export function getChildNames(data: EditPageData, debug: boolean): string {
  const cnames = data.childs
    .filter(v => data.photo.ccodes.some(cd => cd === v.ccode))
    .map(v => (debug ? `${v.ccode}: ${v.cname}` : v.cname));
  const res = cnames.join(', ');
  return res;
}

// --- 写真のダウンロード
export async function downloadImgs(photoNos: number[], bcode: string) {
  if (!photoNos.length) {
    information('写真のチェックボックスをチェックするとダウンロードできます。');
    return;
  }

  const result = await axios.post<Blob>('/api/MasterPhoto/exportoriginals', '', {
    params: {
      nosJson: JSON.stringify(photoNos),
      bcode: bcode,
    },
    responseType: 'blob',
  });
  const blob = new Blob([result.data], { type: result.data.type });

  let name = '';
  const contentDisposition = result.headers['content-disposition'] as string;
  if (contentDisposition) {
    const aster = contentDisposition.match(/ filename\*=UTF-8''((%[0-9A-F]{2}|[^\\/:*?"<>|])+)($|;)/);
    // 一つのヘッダに filename と filename* の両方が存在している場合、
    // filename* は filename より優先される
    if (aster) {
      name = decodeURI(aster[1]);
    } else {
      const matches = contentDisposition.match(/filename="(.*)"/);
      name = matches ? matches[1] : '';
    }
  }

  // アンカー要素(<a>)を生成してクリック
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.setAttribute('download', name);
  a.click();
  URL.revokeObjectURL(url);
}

// --- 頁切り替え
export function addPage(pageSel: SearchResult, add: number) {
  if (add > 0 && pageSel.crtPage < pageSel.pageLength - 1) {
    ++pageSel.crtPage;
  } else if (add < 0 && pageSel.crtPage > 0) {
    --pageSel.crtPage;
  }
}
