import axios from 'axios';
import { axiosGetWithoutSpin } from '@/utility/axios-utilities';
import '@/utility/extentions/string.extension';
import { ListOptions, ListOption } from '@/utility/ListOptions';
import { splitName, toName } from '@/utility/PersonalName';
import { Classification, ClassificationItem } from '@/utility/Classification';
import { isValidMail, isValidTel } from '@/utility/valid';
import { isValidPassword } from '@/utility/spassword';
import { StaffRoll } from '@/utility/consts/DbTables';
import { toDateKey } from '@/utility/date';
import { SaveResultItem } from '@/utility/save-result';

//  移動 zzz  (DbTables.ts)
export const nameALength = 10; //< 姓文字列長
export const nameBLength = 15; //< 名文字列長
export const sMBCodeLength = 6; //< BCode文字列長
export const sNTinyLength = 4; //< 略称
export const sAddressLength = 100; //< 住所
export const sNearStaLength = 10; //< 最寄り駅
export const sTelLength = 20; //< 電話番号
export const sMailLength = 60; //< 電子メールアドレス
export const sMemoLength = 32; //< 備考
export const maxHours = 230; //< 月内の最大労働時間

export const minDate = '1900-01-01';
export const maxDate = '2099-12-31';

export interface Employee {
  sStDate: string;
  sEdDate: string;
  sCode: number;
  sNameA: string;
  sNameB: string;
  sNTiny: string;
  sNKanaA: string;
  sNKanaB: string;
  sSex: number;
  sBirthday: string;
  sEentryDay: string;
  sLeaveDay: string;
  sCondi: number;
  sLeaveStDay: string;
  sLeaveEdDay: string;
  sEmployee: number;
  sCategory: number;
  sPosition: number;
  sMainBase: string;
  sSubBase: string;
  sAddress: string;
  sNearSta1: string;
  sNearSta2: string;
  sTel: string;
  sMail1: string;
  sLicenses: string[];
  sMinHour: number;
  sMaxHour: number;
  sMemo: string;
  sTagNo: number;
  sPassword: string;
  sRoll: string;
  dbOkSCode: number; //< insert可能なSCode
  bgcode: string;
  pkStDate: string; //< ロード時のsStDate
  pkStDates: Date[]; //< 全sStDate(Key)
}
export interface EmployeeSearchItem {
  sCode: number;
  sName: string;
  sEntryDay: string;
  sLeaveDay: string;
  sEmployeeCode: string;
  sEmployeeName: string;
  sEmployeeNameTiny: string;
  sPositionText: string;
  sCategoryText: string;
  sCondi: number;
  sCondiText: string;
  sLeaveStDay: string;
  sLeaveEdDay: string;
  sMainBaseCode: string;
  sMainBaseName1: string;
  sMainBaseName2: string;
  sStDate: string;
}
export interface EmployeeEditItem {
  sstdate: string;
  seddate: string;
  scode: number;
  sname: string;
  sntiny: string;
  snkana: string;
  ssex: number;
  sbirthday: string;
  seentryday: string;
  sleaveday: string;
  scondi: number;
  sleavestday: string;
  sleaveedday: string;
  semployee: number;
  scategory: number;
  sposition: number;
  smainbase: string;
  ssubbase: string;
  saddress: string;
  snearsta1: string;
  snearsta2: string;
  stel: string;
  smail1: string;
  slicenses: string[];
  sminhour: number;
  smaxhour: number;
  smemo: string;
  stagno: number;
  spassword: string;
  sroll: string;
  bgcode: string;
  pkStDates: string[]; //< 取得時のみ利用
}

interface BaseNameItem {
  bCode: string;
  bName1: string;
  bName2: string;
  bkName: string;
  bnTiny: string;
  classes: string[];
}

interface BaseNameItem {
  bCode: string;
  bName1: string;
  bName2: string;
  bkName: string;
  bnTiny: string;
  classes: string[];
}

interface AuthListItem {
  rollId: string;
  rollName: string;
}

//  拠点グループ 及び 拠点選択
interface BaseGroupNameItem {
  code: string; //< bgcode or bcode
  bgname: string | null;
  bName1: string | null;
  bName2: string | null;
  bkName: string | null;
  bnTiny: string | null;
}

// --- list
export const masterStaffSearchCondtionName = 'StaffMSearchCond';

export interface StaffSearchCondition {
  //  従業員区分
  fulltimeEmployee: boolean;
  parttimeEmployee: boolean;
  otherEmployee: boolean;
  //  業務区分
  minderCategory: boolean;
  cookCategory: boolean;
  officeCategory: boolean;
  otherCategory: boolean;

  retirement: boolean;
  keyword: string;
}

export function initStaffSearchCondition(): StaffSearchCondition {
  const res: StaffSearchCondition = {
    //  従業員区分
    fulltimeEmployee: false,
    parttimeEmployee: false,
    otherEmployee: false,
    //  業務区分
    minderCategory: false,
    cookCategory: false,
    officeCategory: false,
    otherCategory: false,

    retirement: false,
    keyword: '',
  };

  return res;
}

// --- vue control
export type BaseName = BaseNameItem;

export interface BaseData {
  keyword: string;
  selectList: ListOptions;
  baseNames: BaseName[];
}

type BaseGroupName = BaseGroupNameItem;

export interface BaseGroupData {
  keyword: string;
  selectList: ListOption<string>[];
  baseGroupNames: BaseGroupName[];
}

export interface PageData {
  classifications: Classification[];
  authList: Record<string, string>;
  edited: boolean;
  baseGroupData: BaseGroupData;
}

export const licenses: ListOptions[] = [
  { value: '011', text: '支援員' },
  { value: '021', text: '保育' },
  { value: '031', text: '幼教諭' },
  { value: '041', text: '小教諭' },
  { value: '051', text: '准看護師' },
  { value: '061', text: '看護師' },
  { value: '071', text: '栄養士' },
  { value: '081', text: '管栄士' },
  { value: '091', text: '調理師' },
  { value: '101', text: '二輪免' },
  { value: '111', text: '普通免' },
];

export function initPageData(): PageData {
  const data: PageData = {
    classifications: [],
    authList: {},
    edited: false,
    baseGroupData: {
      keyword: '',
      selectList: [],
      baseGroupNames: [],
    },
  };
  return data;
}

export function initBaseData() {
  return <BaseData>{
    keyword: '',
    selectList: {},
    baseNames: [],
  };
}
export async function loadBases(baseData: BaseData): Promise<void> {
  const response = await axios.get<BaseNameItem[]>('/api/MasterBase/NameOptions');
  const rows = response.data.map(v => {
    const row: BaseName = {
      bCode: v.bCode,
      bName1: v.bName1,
      bName2: v.bName2,
      bkName: v.bkName,
      bnTiny: v.bnTiny,
      classes: [...v.classes],
    };
    return row;
  });
  baseData.keyword = '';
  baseData.baseNames = [...rows];
  updateBaseList(baseData);
}
export function updateBaseList(baseData: BaseData) {
  const list: ListOptions = {};
  const k = baseData.keyword.trim();
  baseData.baseNames
    .filter(
      v =>
        k === '' ||
        v.bCode === k ||
        v.bName1.indexOf(k) >= 0 ||
        v.bName2.indexOf(k) >= 0 ||
        v.bkName.indexOf(k) >= 0 ||
        v.bnTiny.indexOf(k) >= 0
    )
    .forEach(v => {
      list[v.bCode] = v.bnTiny;
    });
  baseData.selectList = list;
}

//  拠点グループと拠点名の取得 (拠点は他で読み込みしているため不要だ)
export async function loadBaseGroups(model: BaseGroupData) {
  const response = await axios.get<BaseGroupNameItem[]>('/api/MasterBaseGroup/names');
  const rows = response.data.map(v => {
    const row: BaseGroupName = { ...v };
    return row;
  });
  model.keyword = '';
  model.baseGroupNames = [...rows];
  updateBaseGroupList(model);
}

export function updateBaseGroupList(model: BaseGroupData) {
  const k = model.keyword.trim();
  const list: ListOption<string>[] = model.baseGroupNames
    .filter(
      v =>
        k === '' ||
        v.code === k ||
        (v.bgname !== null && v.bgname.indexOf(k) >= 0) ||
        (v.bName1 !== null && v.bName1.indexOf(k) >= 0) ||
        (v.bName2 !== null && v.bName2.indexOf(k) >= 0) ||
        (v.bkName !== null && v.bkName.indexOf(k) >= 0) ||
        (v.bnTiny !== null && v.bnTiny.indexOf(k) >= 0)
    )
    .map(v => {
      const gbname = v.bgname !== null ? `[${v.code}] ${v.bgname}` : v.bnTiny !== null ? v.bnTiny : 'err';
      const res: ListOption<string> = { text: gbname, value: v.code };
      return res;
    });
  const none: ListOption<string> = {
    text: 'なし',
    value: '',
  };
  list.unshift(none);
  model.selectList = list;
}

export function initEmployee(): Employee {
  const today = new Date();
  const beginOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
  return {
    sStDate: `${beginOfMonth.getFullYear()}-${(beginOfMonth.getMonth() + 1).toString().padStart(2, '0')}-${beginOfMonth
      .getDate()
      .toString()
      .padStart(2, '0')}`,
    sEdDate: `9999-12-31`,
    sCode: 0,
    sNameA: '',
    sNameB: '',
    sNTiny: '',
    sNKanaA: '',
    sNKanaB: '',
    sSex: 1,
    sBirthday: `${today.getFullYear()}-${(today.getMonth() + 1).toString().padStart(2, '0')}-${today
      .getDate()
      .toString()
      .padStart(2, '0')}`,
    sEentryDay: `${beginOfMonth.getFullYear()}-${(beginOfMonth.getMonth() + 1).toString().padStart(2, '0')}-${beginOfMonth
      .getDate()
      .toString()
      .padStart(2, '0')}`,
    sLeaveDay: '',
    sCondi: 0,
    sLeaveStDay: `${beginOfMonth.getFullYear()}-${(beginOfMonth.getMonth() + 1).toString().padStart(2, '0')}-${beginOfMonth
      .getDate()
      .toString()
      .padStart(2, '0')}`,
    sLeaveEdDay: `${beginOfMonth.getFullYear()}-${(beginOfMonth.getMonth() + 1).toString().padStart(2, '0')}-${beginOfMonth
      .getDate()
      .toString()
      .padStart(2, '0')}`,
    sEmployee: 10,
    sCategory: 45,
    sPosition: 60,
    sMainBase: '',
    sSubBase: '',
    sAddress: '',
    sNearSta1: '',
    sNearSta2: '',
    sTel: '',
    sMail1: '',
    sLicenses: [],
    sMinHour: 0,
    sMaxHour: 0,
    sMemo: '',
    sTagNo: 0,
    sPassword: '',
    sRoll: StaffRoll.general,
    dbOkSCode: 0,
    bgcode: '',
    pkStDate: '',
    pkStDates: [],
  };
}
export async function loadEmployee(sCode: string, pkstdate: string): Promise<Employee> {
  const employee = (
    await axios.get<EmployeeEditItem>(`/api/MasterStaff/${sCode}`, {
      params: {
        pkstdate: pkstdate,
      },
    })
  ).data;

  const [sNameA, sNameB] = splitName(employee.sname);
  const [sNKanaA, sNKanaB] = splitName(employee.snkana);
  const res: Employee = {
    sStDate: employee.sstdate,
    sEdDate: employee.seddate,
    sCode: employee.scode,
    sNameA: sNameA,
    sNameB: sNameB,
    sNTiny: employee.sntiny,
    sNKanaA: sNKanaA,
    sNKanaB: sNKanaB,
    sSex: employee.ssex,
    sBirthday: employee.sbirthday,
    sEentryDay: employee.seentryday,
    sLeaveDay: employee.sleaveday,
    sCondi: employee.scondi,
    sLeaveStDay: employee.sleavestday,
    sLeaveEdDay: employee.sleaveedday,
    sEmployee: employee.semployee,
    sCategory: employee.scategory,
    sPosition: employee.sposition,
    sMainBase: employee.smainbase,
    sSubBase: employee.ssubbase,
    sAddress: employee.saddress,
    sNearSta1: employee.snearsta1,
    sNearSta2: employee.snearsta2,
    sTel: employee.stel,
    sMail1: employee.smail1,
    sLicenses: employee.slicenses,
    sMinHour: employee.sminhour,
    sMaxHour: employee.smaxhour,
    sMemo: employee.smemo,
    sTagNo: employee.stagno,
    sPassword: employee.spassword,
    sRoll: employee.sroll,
    dbOkSCode: 0,
    bgcode: employee.bgcode,
    pkStDate: employee.sstdate, //< loadで指定したStDate
    pkStDates: employee.pkStDates.map(v => v.dateTextToDate()),
  };

  return Promise.resolve(res);
}

export async function isExistRecord(sCode: number, stDate: string): Promise<boolean> {
  const strDate = toDateKey(stDate.dateTextToDate());
  const res = await axios.get<boolean>(`/api/MasterStaff/isExist/${sCode}`, {
    params: {
      pkstdate: strDate,
    },
  });
  return res.data;
}

export async function addEmployee(sCode: number, employee: Employee) {
  await axios.post(`/api/MasterStaff/${sCode}`, toEditItem(employee));
}
export async function updateEmployee(sCode: number, employee: Employee): Promise<SaveResultItem> {
  const response = await axios.put<SaveResultItem>(`/api/MasterStaff/${sCode}`, toEditItem(employee), {
    params: {
      pkStDate: employee.pkStDate, //< 編集画面のロード時の値(pk)を渡す yyyy-MM-dd形式
    },
  });
  return response.data;
}
export async function deleteEmployee(sCode: number, pkStDate: string) {
  await axios.delete(`/api/MasterStaff/${sCode}`, {
    params: {
      pkStDate: pkStDate, //< 編集画面のロード時の値(pk)を渡す yyyy-MM-dd形式
    },
  }); //< SStDateの設定が必要 機能未搭載により未対応
}
export async function setMaxSCode(employee: Employee) {
  axios.get<number>('/api/MasterStaff/maxscode').then(response => {
    employee.sCode = response.data + 1;
  });
}

function toEditItem(employee: Employee): EmployeeEditItem {
  const item: EmployeeEditItem = {
    sstdate: employee.sStDate,
    seddate: employee.sEdDate,
    scode: employee.sCode,
    sname: toName(employee.sNameA, employee.sNameB),
    sntiny: employee.sNTiny,
    snkana: toName(employee.sNKanaA, employee.sNKanaB),
    ssex: employee.sSex,
    sbirthday: employee.sBirthday,
    seentryday: employee.sEentryDay,
    sleaveday: employee.sLeaveDay,
    scondi: employee.sCondi,
    sleavestday: employee.sLeaveStDay,
    sleaveedday: employee.sLeaveEdDay,
    semployee: employee.sEmployee,
    scategory: employee.sCategory,
    sposition: employee.sPosition,
    smainbase: employee.sMainBase,
    ssubbase: employee.sSubBase,
    saddress: employee.sAddress,
    snearsta1: employee.sNearSta1,
    snearsta2: employee.sNearSta2,
    stel: employee.sTel,
    smail1: employee.sMail1,
    slicenses: employee.sLicenses,
    sminhour: employee.sMinHour,
    smaxhour: employee.sMaxHour,
    smemo: employee.sMemo,
    stagno: employee.sTagNo,
    spassword: employee.sPassword,
    sroll: employee.sRoll,
    bgcode: employee.bgcode,
    pkStDates: [],
  };
  return item;
}

export function isValidNew(employee: Employee) {
  return checkSCode(employee) && isValidEdit(employee);
}

export function isValidEdit(employee: Employee) {
  return checkSMBCode(employee) && checkStDate(employee) && checkEntryDate(employee) && checkLeaveDate(employee) && checkBirthDay(employee);
  /* org return [
    checkSCode(employee),
    checkSNameA(employee),
    checkSNameB(employee),
    checkSNKanaA(employee),
    checkSNKanaB(employee),
    checkSMBCode(employee),
    checkBGCode(employee),
    checkSNTiny(employee),
    checkSAddress(employee),
    checkSNearSta1(employee),
    checkSNearSta2(employee),
    checkSMail1(employee),
    checkSMemo(employee),
    checkSPassword(employee),
    checkMaxHour(employee),
    checkMinHour(employee),
  ].every(v => v === true); */
}

export function checkSCode(employee: Employee): boolean {
  return employee.sCode > 0 && employee.sCode === employee.dbOkSCode;
}
export function checkSNameA(employee: Employee): boolean {
  const str = employee.sNameA.trim().replaceAll('　', ' ');
  return str.indexOf(' ') < 0 && str.length > 0 && str.length < nameALength + 1;
}
export function checkSNameB(employee: Employee): boolean {
  const str = employee.sNameB.trim().replaceAll('　', ' ');
  return str.length > 0 && str.length < nameBLength + 1;
}
export function checkSNKanaA(employee: Employee): boolean {
  const str = employee.sNKanaA.trim().replaceAll('　', ' ');
  const res = str.match(/^[ぁ-んー\s]*$/);
  return res !== null && str.indexOf(' ') < 0 && str.length > 0 && str.length < nameALength + 1;
}
export function checkSNKanaB(employee: Employee): boolean {
  const str = employee.sNKanaB.trim().replaceAll('　', ' ');
  const res = str.match(/^[ぁ-んー\s]*$/);
  return res !== null && str.length > 0 && str.length <= nameBLength;
}
export function checkSMBCode(employee: Employee): boolean {
  // const str = employee.sMainBase.trim();
  // return str.length > 0 && str.length <= sMBCodeLength;
  return true;
}
export function checkBGCode(employee: Employee): boolean {
  return true;
}
export function checkSNTiny(employee: Employee): boolean {
  const str = employee.sNTiny.trim();
  return str.length > 0 && str.length <= sNTinyLength; //< sNTinyLength = 4 // 略称
}
export function checkSAddress(employee: Employee): boolean {
  const str = employee.sAddress.trim();
  return str.length <= sAddressLength; // 住所
}
export function checkSNearSta1(employee: Employee): boolean {
  const str = employee.sNearSta1.trim();
  return str.length <= sNearStaLength; // 最寄り駅
}
export function checkSNearSta2(employee: Employee): boolean {
  const str = employee.sNearSta2.trim();
  return str.length <= sNearStaLength;
}
export function checkSTel(employee: Employee): boolean {
  return !employee.sTel || isValidTel(employee.sTel, sTelLength); // 電話番号
}
export function checkSMail1(employee: Employee): boolean {
  return !employee.sMail1 || isValidMail(employee.sMail1, sMailLength); // 電子メールアドレス
}
export function checkSMemo(employee: Employee): boolean {
  const str = employee.sMemo.trim();
  return str.length <= sMemoLength; // 備考
}
export function checkSPassword(employee: Employee): boolean {
  const str = employee.sPassword.trim();
  return isValidPassword(str);
}

export function checkMaxHour(employee: Employee): boolean {
  return employee.sMaxHour >= 0 && employee.sMaxHour < maxHours && employee.sMinHour <= employee.sMaxHour;
}

export function checkMinHour(employee: Employee): boolean {
  return employee.sMinHour >= 0 && employee.sMinHour < maxHours && employee.sMinHour <= employee.sMaxHour;
}

export async function checkExistSCode(employee: Employee) {
  if (typeof employee.sCode !== 'number') return;
  const ccd: number = employee.sCode;
  axiosGetWithoutSpin<number>('/api/MasterStaff/ExistSCode/' + ccd).then(response => {
    employee.dbOkSCode = response.data;
  });
}

export function checkEntryDate(employee: Employee): boolean {
  if (!employee.sEentryDay) return false;
  return minDate <= employee.sEentryDay && employee.sEentryDay < maxDate;
}

export function checkLeaveDate(employee: Employee): boolean {
  if (!employee.sLeaveDay) return true;
  return minDate <= employee.sLeaveDay && employee.sLeaveDay < maxDate;
}

export function checkStDate(employee: Employee): boolean {
  if (!employee.sStDate) return false;
  return minDate <= employee.sStDate && employee.sStDate < maxDate;
}

export function checkBirthDay(employee: Employee): boolean {
  if (!employee.sBirthday) return false;
  return minDate <= employee.sBirthday && employee.sBirthday < maxDate;
}

// --- classificaiton
export async function loadClassification(data: PageData) {
  axios
    .get<ClassificationItem[]>('/api/MasterClassification/', {
      params: {
        kcodes: ['役職', '業務', '従事者', '性別S', '就業', '休業'].join(','),
      },
    })
    .then(response => {
      data.classifications = response.data.map(v => {
        const res: Classification = { ...v };
        return res;
      });
    });
}

export function getListOptions(classficaitons: Classification[], kcode: string, reverse = false): ListOption<number>[] {
  const dir = reverse ? -1 : 1;
  const res = classficaitons
    .filter(v => v.kcode === kcode)
    .sort((a, b) => {
      if (a.kinid < b.kinid) return -dir;
      else if (a.kinid > b.kinid) return dir;
      return 0;
    })
    .map(v => {
      const opt: ListOption<number> = {
        value: v.kinid,
        text: v.kntiny,
      };
      return opt;
    });
  return res;
}

// --- 操作権限
export async function loadAuthList(data: PageData) {
  axios.get<AuthListItem[]>('/api/MasterStaff/list').then(response => {
    const rec: Record<string, string> = {};
    response.data.forEach(v => {
      rec[v.rollId] = v.rollName;
    });
    data.authList = rec;
  });
}

// ---
export const askEditMsg = '編集デーが保存されていません。保存せず移動しますか？';
export const askCreate = '新たに履歴データが作成されようとしています。保存しますか？';
