import { ref } from 'vue';

export const authError = 'auth-error';

export interface Message {
  type: 'info' | 'error' | 'confirm' | 'yesno' | 'auth-error';
  text: string;
  style?: string;
  buttonStyle?: string;
  resolve: (result: boolean) => void;
}

export const messages = ref<Message[]>([]);

export function alert(text: string, style = 'width: auto') {
  return new Promise(resolve =>
    messages.value.push({
      type: 'error',
      text,
      style: style,
      resolve,
    })
  );
}

export function information(text: string, style = 'width: auto') {
  return new Promise(resolve =>
    messages.value.push({
      type: 'info',
      text,
      style: style,
      resolve,
    })
  );
}

export function confirm(text: string, style = 'width: auto') {
  return new Promise(resolve =>
    messages.value.push({
      type: 'confirm',
      text,
      style: style,
      resolve,
    })
  );
}

export function yesno(text: string, style = 'width: auto', buttonStyle?: string) {
  return new Promise(resolve =>
    messages.value.push({
      type: 'yesno',
      text,
      style: style,
      buttonStyle: buttonStyle,
      resolve,
    })
  );
}

export function authAlert(text: string, style = 'width: auto') {
  return new Promise(resolve =>
    messages.value.push({
      type: authError,
      text,
      style: style,
      resolve,
    })
  );
}
