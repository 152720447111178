// --- 拠点
export const bCodeLength = 6;
export const bCodeSubLength = 2;
export const bCodeBaseLength = bCodeLength - bCodeSubLength;

//  --- 従事者
export const cateNone = 0;
export const cateMinder = 40;
export const cateCook = 38;
export const cateDuplicate = 9999;
export const passwordLength = 16;
export const sPwMinLength = 8; //< パスワード最小
export const sPwMaxLength = 60; //< パスワード最大 DBの定義と違う zzz

//  --- 保護者
export const pPwMinLength = 8; //< パスワード最小
export const pPwMaxLength = 60; //< パスワード最大

//  zzz 削除 mclassificationに切替
export const categoryRec: Record<number, string> = {
  45: '園長',
  44: '園主任',
  40: '保育',
  39: '看護',
  38: '調理',
  36: '事務',
  35: '連携員',
  32: '警備',
  31: '送迎',
  22: '研修',
  21: '栄養士',
  15: '特出',
  10: '本部',
};

export const requiredKinidMax = 10; // 全拠点必須業務

export const employeeRec: Record<number, string> = {
  10: '役員',
  11: '総合',
  12: '一般',
  13: 'プロパ',
  14: '準社',
  15: '特社',
  16: 'パート',
  17: 'メイト',
  18: '派遣',
  19: 'その他',
};

export const SCondi = {
  normal: 0, //< 通常
  retired: 1, //< 退社
  birth: 10, //< 産休
  childcare: 20, //  育休
  nursing: 30, //  介休
  disease: 40, //  病休
} as const;

//  classification
export const KCode = {
  trans: '交通手段',
  sCategory: '業務',
  staffMemo: '従事者メモ',
  vacation: '休暇',
  childMemo: '児童メモ',
  sPaidH: '従有給',
  sPosition: '役職',
  photoSection: '写真区分',
} as const;

// --- 打刻
// オブジェクトリテラルに切替予定 zzz
export const cPunchTypeFrom = 4;
export const cPunchTypeTo = 6;

//  こちらに統一 名前変更 もしくは 名前空間 zzz
export const Punch = { In: 4, Out: 6 } as const;
export type Punch = typeof Punch[keyof typeof Punch];

// --- 休暇
export const vacationHoliday = '90';

//  --- 児童 ハンディキャップ
export const handN = 0; //< 正常児
export const handH1 = 1; //< 特殊児1 使用禁止 mclassificationから取得に
export const handH2 = 2; //< 特殊児2 使用禁止 mclassificationから取得に
export const handH3 = 3; //< 特殊児3 使用禁止 mclassificationから取得に
export const handSys = 9; //< システム児

//  --- 児童 予約区分
export const ConstAge = {
  a0: 0,
  a1: 1,
  a2: 2,
  a3: 3,
  a4: 4,
  a5: 5,
  a5Over: 6,
  max: 7,
} as const;

//  --- 児童 年齢区分 同じものが master-base.tsにある zzz
export const capTbl = ['0歳', '1歳', '2歳', '3歳', '4歳', '5歳', '5歳超'];

// 1日メモ
export const contentLength = 100;
export const statusLength = 32;

//  有給消化管理
export const paidMemoLength = 20;

export const EFromTo = {
  none: 0, //< 無印	登園予定時刻以前
  noIn: 1, //< 未登園	登園時刻以降で登園打刻していない
  preIn: 2, //< 確認（登園待ち）	未登園で保護者確認し、遅れているとき
  cancel: 3, //< 確認（当日キャンセル）	未登園で保護者確認し、登園しないとき
  in: 4, //< 在園中	登園打刻されたとき
  extension: 5, //< 延長中	登園打刻があり、退園予定時刻以降
  out: 6, //< 降園  降園打刻されたとき
} as const;

//  児童 登園待ち・当キャ判定用 この配列に含まれない場合、状態判定に影響なし
export const ChildTitleStatus = {
  late: [1], //< 遅刻の連絡あり
  cancel: [10, 20, 30, 31, 90], //< 当キャのkinid
} as const;

//  従事者 出勤待ち・欠勤判定用 この配列に含まれない場合、状態判定に影響なし
export const StaffTitleStatus = {
  late: [1], //< 遅刻の連絡あり
  cancel: [10, 20, 30, 31, 90], //< 当キャのkinid
} as const;

export const HolidayType = {
  dayoff: '有給休',
  dayoffAM: '午前有給',
  dayoffPM: '午後有給',
  vacation: '年次休',
} as const;

export const RMethodType = {
  public: 20, //< 公共の交通手段
  car: 60, //< 車
  advance: 90, //< 立替
} as const;

//  MStaff.sroll定義 スペルミス防止用
export const StaffRoll = {
  admin: 'admin',
  general: 'general',
} as const;

//  保護者お知らせ
export const ConstPInform = {
  subjectLength: 30,
  contentLength: 1000,
  pdfFnameLength: 256,
} as const;

// 写真
export const ConstPhoto = {
  commentLength: 120,
  shotAreaLength: 30,
  photoSectionLength: 10,
  photoTypeLength: 10,
} as const;

//  写真 状態
export const EPublicSt = {
  stop: 0, //< 配信停止
  public: 1, //< 配信
  draft: 2, //< 下書き保存
  remand: 3, //< 差し戻し
  reqApproval: 4, //< 承認依頼
} as const;

//  写真 Ricフラグ
export const ERicSign = {
  None: 0,
  Ric: 1,
  Paid: 2,
};
