import axios from 'axios';
import { ListOptions, ListOptionNum, noneOptNum } from '@/utility/ListOptions';
import { toDateKey, indexToTime, isValidDate, toDate, toMMddhhmm } from '@/utility/date';
import { BasicData, initBasicData, BaseOption } from '@/utility/BaseOpts';
import { isValidLength } from '@/utility/valid';
import { PulldownListItem, PulldownList, PulldownListRow } from '@/utility/PulldownList';
import { axiosGetWithoutSpin } from '@/utility/axios-utilities';
import { eatKomas } from '@/utility/Eat';
import { getLoginUser } from '@/utility/login';
import { maintMTable, checkMaintMTable } from '@/utility/maint';
import { yesno } from '@/utility/messages';
import { Punch, KCode, EFromTo } from '@/utility/consts/DbTables';
import { C4Punch, C4PunchEdit } from '@/utility/ChildPunch';
import { dtTextToDate } from '@/utility/date';
import { RepunchCtrl } from '@/utility/RepunchDialog';
import { ClassificationItem, createListOptionNum } from './Classification';
import { CMemoItem } from '@/utility/children-memo';
import { SMemoItem } from '@/utility/staff-memo';

// --- transfer
interface BaseItem {
  movement: MovementItem;
  childNameOpts: ListOptionItem<number>[];
  childClassOpts: ListOptionItem<number>[];
  staffOpts: ListOptionItem<number>[];
  classes: string[];
  pulldownListItems: PulldownListItem[];
  classifications: ClassificationItem[];
  month: string;
  deadline: string;
}

interface MovementItem {
  dDailyItem: DDailyItem;
  dAlarmItems: DAlarmItem[];
  weatherUrl: string;
  wbgtUrl: string;
  alertUrl: string;
  cScheduleCountD: number; //< 児童予定 昼
  cScheduleCountN: number; //< 児童予定 夜
  cPunchCountD: number; //< 児童打刻 昼
  cPunchCountN: number; //< 児童打刻 夜
  sScheduleCountD: number; //< 従事者予定 昼
  sScheduleCountN: number; //< 従事者予定 夜
  sPunchCountD: number; //< 従事者打刻 昼
  sPunchCountN: number; //< 従事者打刻 夜
}

interface MovementUpdateItem {
  dDailyItem: DDailyItem;
  dAlarmItems: DAlarmItem[];
}

interface DDailyItem {
  no: number;
  bCode: string;
  targetDateText: string;

  todayReader: string;
  weather: string;
  heatIndexText: string;
  place: string;
  weather1Text: string;
  weather2Text: string;
  weather3Text: string;
  humidity1Text: string;
  humidity2Text: string;
  humidity3Text: string;
}

interface DAlarmItem {
  no: number;
  bCode: string;
  targetDateText: string;

  alarmName: string;
  stTimeText: string;
  edTimeText: string;
}

interface DEventItem {
  no: number;
  bCode: string;
  targetDateFromText: string;
  targetDateToText: string;
  itemName: string;
  itemUrl: string;
  startDateText: string;
  endDateText: string;
}

interface DEventUpdateItem {
  no: number;
  bCode: string;
  targetDateFromText: string;
  targetDateToText: string;
  itemName: string;
  itemUrl: string;
  startDateText: string;
  endDateText: string;
  sCode: number;
}

interface ListOptionItem<T> {
  key: T;
  value: string;
}

interface ChildInOutPunchItem {
  childInOuts: ChildInOutItem[];
  childPunches: C4Punch[];
}

interface ChildInOutItem {
  scheduleNo?: number;
  cCode: number;
  cName: string; //  児童
  dateText?: string;
  fromKoma?: number; //  登園予定
  toKoma?: number; //	降園予定
  scheduleMeals: number[];
  resultMeals: number[];
  punchNo?: number;
  punchTimeA?: string; //< 実績 or 打刻 の開始時刻
  punchTimeB?: string; //< 実績 or 打刻 の終了時刻
  punchFromTo?: number; //< 在園状況
  punchTemper?: string;
  memoId: number;
  memoTitle: number;
  memoContent: string;
  memoStatus: string;
  memoPrevTitle: number; //< 一日メモ 前日分
  memoPrevContent: string; //< 一日メモ 前日分
}

interface StaffInOutItem {
  scheduleNo?: number; //< 使用している様子無し zzz
  sCode: number;
  sName: string; //  スタッフ
  dateText?: string;
  fromKoma?: number; //  予定・開始
  toKoma?: number; //	予定・終了
  scheduleCategory?: number; //< 予定業務
  punchNo?: number; //< 使用している様子無し zzz
  punchTimeA?: string; //< 実績 or 打刻 の開始時刻
  punchTimeB?: string; //< 実績 or 打刻 の終了時刻
  punchFromTo?: number; //< 出勤状況
  punchTemper?: string; //< 勤務状況 勤務中・退勤等
  punchCategory?: number; //< 実績 or 打刻の業務
  memoId: number;
  memoTitle: number; //< 一日メモ 当日分
  memoContent: string; //< 一日メモ 当日分
  memoStatus: string;
  memoPrevTitle: number; //< 一日メモ 前日分
  memoPrevContent: string; //< 一日メモ 前日分
}

interface CHealthItem {
  no: number;
  cCode: number; //<  児童
  time: string; //< 時間
  case: string;
  situation: string; //< 体調状況(発熱 下痢 嘔吐 その他)
  action: string; //<  対応内容
  contact: string; //<  保護者連絡
  sCode: number; //<  対応保育士
  makeSCode: number;
}

interface CHealthUpdateItem {
  no: number;
  cCode: number; //<  児童
  time: string; //< 時間
  case: string;
  situation: string; //< 体調状況(発熱 下痢 嘔吐 その他)
  action: string; //<  対応内容
  contact: string; //<  保護者連絡
  sCode: number; //<  対応保育士
}

interface DInformItem {
  no: number;
  bCode: string;
  startDateText: string;
  endDateText: string;
  titleName: string;
  targetCCode: number;
  targetSCode: number;
  itemName: string;
  contents: string;
  sCode: number;
  makeDateText: string;
}

interface DInformUpdateItem {
  no: number;
  bCode: string;
  startDateText: string;
  endDateText: string;
  titleName: string;
  targetCCode: number;
  targetSCode: number;
  itemName: string;
  contents: string;
  sCode: number;
}

interface CAllergyCheckItem {
  no: number;
  cCode: number;
  case: number;
  cMeal: number;
  foods: string;
  yorN: number;
  check: number;
}

interface CAllergyCheckUpdateItem {
  no: number;
  cCode: number;
  case: number;
  cMeal: number;
  foods: string;
  check: number;
}

interface EatScheduleUpdateItem {
  cCode: number;
  bCode: string;
  cMeal: number;
  cMealReserve: number; //< 0...予約なし  1...予約なし
}

interface EatResultUpdateItem {
  cCode: number;
  bCode: string;
  cMeal: number;
  cMealEat: number; //< ０...未接種 1...食べた 2...食べなかった
}

// --- vue controll
export interface HomeData {
  basic: BasicData;

  movement: Movement;
  editedMovement: boolean;
  childNameRec: Record<number, string>;
  childNameOpts: ListOptionNum[];
  childClassOpts: ListOptions;
  staffNameRec: Record<number, string>;
  staffNameOpts: ListOptionNum[];
  classes: string[];
  pulldownLists: PulldownList[];
  cmTitleOpts: ListOptionNum[];
  smTitleOpts: ListOptionNum[];
  categoryOpts: ListOptionNum[];

  dEvents: DEvent[];

  childInOuts: ChildInOut[];
  c4punches: C4Punch[]; // homeDataに移動
  staffInOuts: StaffInOut[];

  cHealthes: CHealth[];
  dInforms: DInform[];
  cAllergyCheck: CAllergyCheck[];
  eventDialog: EventDialog;

  className: string;

  //  希望シフト締め切り
  month: string;
  deadline: string;
}

//  日
export interface Movement extends Omit<MovementItem, 'dDailyItem' | 'dAlarmItems'> {
  dDaily: DDaily;
  dAlarms: DAlarm[];
}

interface DDaily extends Omit<DDailyItem, 'targetDateText' | 'todayReader'> {
  targetDate: Date;
  todayReaderSCode: number;
}

interface DAlarm extends Omit<DAlarmItem, 'targetDateText'> {
  targetDate: Date;
  editStatus: 0 | 1;
}

export interface DEvent extends Omit<DEventItem, 'targetDateFromText' | 'targetDateToText' | 'startDateText' | 'endDateText'> {
  targetDateFrom: Date;
  targetDateTo: Date;
  startDate: Date;
  endDate: Date;
}

//  児童の登降園状況
export interface ChildInOut extends ChildInOutItem {
  date?: Date;
}

//  従業員の出退勤状況
export interface StaffInOut extends StaffInOutItem {
  date?: Date;
}

//  児童の体調・怪我
export interface CHealth extends CHealthItem {
  editStatus: number; //< 編集中 0...表示 1...編集中
}

export interface DInform extends Omit<DInformItem, 'startDateText' | 'endDateText'> {
  startDate: Date;
  endDate: Date;
  editStatus: number; //< 編集中 0...表示 1...編集中
}

interface CAllergyCheck extends Omit<CAllergyCheckItem, 'check'> {
  checkB: boolean;
}

export interface EventDialog {
  disp: boolean;
  type: 1 | 2; //< 1 ... add, 2 ... edit
  dEvent: DEvent;
}

//  --- 全体
export const initHomeData: HomeData = {
  basic: initBasicData,
  movement: {
    dDaily: {
      no: 0,
      bCode: '',
      targetDate: new Date(),
      todayReaderSCode: 0,
      weather: '',
      heatIndexText: '',
      place: '',
      weather1Text: '',
      weather2Text: '',
      weather3Text: '',
      humidity1Text: '',
      humidity2Text: '',
      humidity3Text: '',
    },
    dAlarms: [],
    weatherUrl: '#',
    wbgtUrl: '#',
    alertUrl: '#',
    cScheduleCountD: 0, //< 児童 昼
    cPunchCountD: 0, //< 児童 昼
    cScheduleCountN: 0, //< 児童 夜
    cPunchCountN: 0, //< 児童 夜
    sScheduleCountD: 0, //< 従事者 昼
    sPunchCountD: 0, //< 従事者 夜
    sScheduleCountN: 0, //< 従事者 昼
    sPunchCountN: 0, //< 従事者 夜
  },
  editedMovement: false,
  dEvents: [],
  childNameRec: {},
  childNameOpts: [],
  childClassOpts: {},
  staffNameRec: {},
  staffNameOpts: [],
  classes: [],
  pulldownLists: [],
  cmTitleOpts: [],
  smTitleOpts: [],
  categoryOpts: [],
  childInOuts: [],
  c4punches: [],
  staffInOuts: [],
  cHealthes: [],
  dInforms: [],
  cAllergyCheck: [],
  eventDialog: {
    disp: false,
    type: 1,
    dEvent: {
      no: 0,
      bCode: '',
      itemName: '',
      itemUrl: '',
      targetDateFrom: new Date(),
      targetDateTo: new Date(),
      startDate: new Date(),
      endDate: new Date(),
    },
  },
  className: '',
  month: '',
  deadline: '',
};

//  --- move / child on base / staff on base / classes on base / pulldownList
export async function loadBase(home: HomeData, dateKey: string, bCode: string) {
  axios.get<BaseItem>(`/api/home/base/${dateKey}`, { params: { bcode: bCode } }).then(response => {
    const movementItem = response.data.movement;
    home.movement = {
      ...movementItem,
      dDaily: {
        ...movementItem.dDailyItem,
        targetDate: toDate(new Date(movementItem.dDailyItem.targetDateText)),
        todayReaderSCode: home.basic.sssStaffOpts.find(v => v.sName === movementItem.dDailyItem.todayReader.trim())?.sCode ?? 0,
      },
      dAlarms: movementItem.dAlarmItems.map(v => {
        const dAlarm: DAlarm = {
          ...v,
          targetDate: new Date(v.targetDateText),
          editStatus: 0,
        };
        return dAlarm;
      }),
    };

    //  児童名
    const childNameRec: Record<number, string> = {};
    response.data.childNameOpts.forEach(v => {
      childNameRec[v.key] = v.value;
    });
    home.childNameRec = childNameRec;
    home.childNameOpts = response.data.childNameOpts.map(v => {
      const res: ListOptionNum = {
        value: Number(v.key),
        text: `${v.key}: ${v.value}`,
      };
      return res;
    });

    //  児童クラス名
    const childClassOpts: ListOptions = {};
    response.data.childClassOpts.forEach(v => {
      childClassOpts[v.key] = v.value;
    });
    home.childClassOpts = childClassOpts;

    //  従事者名
    const staffOpts: Record<number, string> = {};
    response.data.staffOpts.forEach(v => {
      staffOpts[v.key] = v.value;
    });
    home.staffNameRec = staffOpts;
    home.staffNameOpts = response.data.staffOpts.map(v => {
      const res: ListOptionNum = {
        value: Number(v.key),
        text: `${v.key}: ${v.value}`,
      };
      return res;
    });

    //  クラス名
    if (response.data.classes.length === 0 || (response.data.classes.length === 1 && response.data.classes[0].length === 0)) {
      home.classes.length = 0;
      home.className = '';
    } else {
      home.classes = ['全て'].concat([...response.data.classes]);
      home.className = '全て';
    }

    //  PulldownList
    home.pulldownLists = response.data.pulldownListItems.map(item => {
      const rows: PulldownListRow[] = item.rowItems.map(ritem => {
        const row: PulldownListRow = {
          ...ritem,
          editStatus: 0,
        };
        return row;
      });
      const list: PulldownList = {
        ...item,
        rows: rows,
      };
      return list;
    });

    //  classification
    const cs = response.data.classifications;

    //  児童メモ
    const childMemoOpts = createListOptionNum(cs, KCode.childMemo);
    childMemoOpts.unshift(noneOptNum);
    home.cmTitleOpts = childMemoOpts;

    //  従事者メモ
    const staffMemoOpts = createListOptionNum(cs, KCode.staffMemo);
    staffMemoOpts.unshift(noneOptNum);
    home.smTitleOpts = staffMemoOpts;

    //  業務
    home.categoryOpts = createListOptionNum(cs, KCode.sCategory);

    //  希望シフト締め
    home.month = response.data.month;
    home.deadline = response.data.deadline;
  });
}

//  報連相用 LitOpt
export function getChildNameOpts(home: HomeData): ListOptionNum[] {
  const opts: ListOptionNum[] = [
    { value: -1, text: 'なし' },
    { value: 0, text: '全員' },
  ];
  return opts.concat(home.childNameOpts);
}

//  --- 行事
export async function loadDEvents(home: HomeData, dateKey: string, bCode: string) {
  await axios.get<DEventItem[]>(`/api/home/devent/${dateKey}`, { params: { bcode: bCode } }).then(response => {
    const dEvents = response.data.map(v => {
      const ev: DEvent = {
        ...v,
        targetDateFrom: toDate(new Date(v.targetDateFromText)),
        targetDateTo: toDate(new Date(v.targetDateToText)),
        startDate: toDate(new Date(v.startDateText)),
        endDate: toDate(new Date(v.endDateText)),
      };
      return ev;
    });
    home.dEvents = dEvents;
  });
}

export async function updateAndLoadDEvents(event: DEvent, home: HomeData, dateKey: string, bCode: string) {
  const item: DEventUpdateItem = {
    ...event,
    targetDateFromText: toDateKey(event.targetDateFrom),
    targetDateToText: toDateKey(event.targetDateTo),
    startDateText: toDateKey(event.startDate),
    endDateText: isValidDate(event.endDate) ? toDateKey(event.endDate) : '',
    sCode: getLoginUser()?.staffCode ?? 0, //< プルダウンによる設定が適切と思われる zzz
  };
  await axios.put('/api/home/DEvent', item);
  await loadDEvents(home, dateKey, bCode);
}

export async function deleteAndLoadDEvent(home: HomeData, no: number, dateKey: string, bCode: string): Promise<void> {
  await axios.delete('/api/home/DEvent/' + no);
  await loadDEvents(home, dateKey, bCode);
}

//  --- 児童 予定・実績
export async function loadChildInOut(home: HomeData, dateKey: string, bCode: string) {
  await axios.get<ChildInOutPunchItem>(`/api/home/ChildInOut/${dateKey}`, { params: { bcode: bCode } }).then(response => {
    const data = response.data;

    //  登園・降園
    const childs = data.childInOuts.map(v => {
      const val: ChildInOut = {
        ...v,
        date: v.dateText?.dateTextToDate(),
      };
      return val;
    });
    home.childInOuts = childs;

    //  打刻
    home.c4punches = [...data.childPunches];
  });
}

export async function addC4Punch(repunchCtrl: RepunchCtrl, bCode: string) {
  const dtTexts = repunchCtrl.dateText.split('-');
  const dt = new Date(Number(dtTexts[0]), Number(dtTexts[1]) - 1, Number(dtTexts[2]), repunchCtrl.hour, repunchCtrl.minute, 0);

  const item: C4PunchEdit = {
    bCode: bCode,
    cCode: repunchCtrl.code,
    cStatus: 1, //< 押し忘れ
    cPunchTime: dt.toJSON(),
    writeSCode: getLoginUser()?.staffCode ?? 0,
    writeNote: '【打刻忘れ】',
    cFromTo: repunchCtrl.punchType,
    toBodyTemper: 0,
  };
  await axios.post('/api/ChildrenPunch/', item);
}

export function filteredC4Punches(home: HomeData, cCode: number) {
  return home.c4punches.filter(v => v.ccode === cCode);
}

//  最新が先頭にsort
export function sortByPunchTime(home: HomeData, cCode: number) {
  return filteredC4Punches(home, cCode).sort((a, b) => {
    if (a.cpunchtime > b.cpunchtime) return -1;
    else if (a.cpunchtime < b.cpunchtime) return 1;
    else if (a.no > b.no) return -1;
    else if (a.no < b.no) return 1;
    return 0;
  });
}

//  打刻忘れの表示時刻の初期値の取得
export function repunchInitDate(home: HomeData, cCode: number) {
  const sorted = sortByPunchTime(home, cCode);
  if (sorted.length === 0 || sorted[0].cfromto === Punch.Out) return new Date();
  return dtTextToDate(sorted[0].cpunchtime);
}

//  登園打刻又は降園打刻のDateを取得
export function lastPunchDate(home: HomeData, cCode: number, punch: Punch) {
  const sorted = sortByPunchTime(home, cCode).filter(v => v.cfromto === punch);
  return sorted.length > 0 ? dtTextToDate(sorted[0].cpunchtime) : undefined;
}

//  児童 打刻忘れボタンの表示の可否
export function isEnabledRepunch(home: HomeData, cCode: number, fromTo: Punch, targetDate: Date): boolean {
  if (toDate(new Date()).getTime() !== targetDate.getTime()) return false;
  const sorted = sortByPunchTime(home, cCode);
  if (sorted.length === 0) return fromTo === Punch.In;
  return sorted[0].cfromto !== fromTo;
}

//  --- 従事者 予定・実績
export async function loadStaffInOut(home: HomeData, dateKey: string, bCode: string) {
  await axios.get<StaffInOutItem[]>(`/api/home/StaffInOut/${dateKey}`, { params: { bcode: bCode } }).then(response => {
    const staffs = response.data.map(v => {
      const val: StaffInOut = {
        ...v,
        date: v.dateText?.dateTextToDate(),
      };
      return val;
    });
    home.staffInOuts = staffs;
  });
}

//  --- 児童 体調・怪我
export async function loadChildHealth(home: HomeData, dateKey: string, bCode: string) {
  await axios.get<CHealthItem[]>(`/api/home/CHealth/${dateKey}`, { params: { bcode: bCode } }).then(response => {
    const conds = response.data.map(v => {
      return { ...v, editStatus: 0 };
    });
    home.cHealthes = conds;
  });
}

async function updateChildHealth(health: CHealth, dateKey: string, bCode: string) {
  health.editStatus = 0;
  const item: CHealthUpdateItem = { ...health };
  await axios.put(`/api/home/CHealth/${dateKey}`, item, { params: { bcode: bCode } });
}

export async function updateAndLoadChildHealth(health: CHealth, home: HomeData, dateKey: string, bCode: string) {
  await updateChildHealth(health, dateKey, bCode);
  await loadChildHealth(home, dateKey, bCode);
}

export const OptNone = 'none';

export function addChildCondition(home: HomeData): void {
  const no = home.dInforms.map(v => v.no).reduce((acc, crt) => (acc < crt ? acc : crt), 0) - 1;
  const nextNo = no < 0 ? no : -1;
  const def: CHealth = {
    no: nextNo,
    cCode: 0,
    time: '', //< 時間
    case: '',
    situation: '', //< 状況
    action: '', //<  対応内容
    sCode: 0, //<  対応保育士
    contact: '', //<  保護者連絡
    makeSCode: 0,
    editStatus: 1,
  };
  home.cHealthes.push(def);
}

export function isValidCHealthSituation(str: string): boolean {
  return isValidLength(str, 8);
}

export function isValidCHealthAction(str: string): boolean {
  return isValidLength(str, 64);
}

export function isValidCHealthContact(str: string): boolean {
  return isValidLength(str, 32);
}

export function isValidChildCondtion(health: CHealth): boolean {
  return (
    health.cCode > 0 &&
    health.time.length === 5 &&
    isValidCHealthSituation(health.situation) &&
    isValidCHealthAction(health.action) &&
    health.sCode > 0 &&
    isValidCHealthContact(health.contact)
  );
}

async function deleteChildCondtion(home: HomeData, no: number): Promise<void> {
  if (no <= 0) {
    home.cHealthes = home.cHealthes.filter(v => v.no != no);
    return;
  }
  await axios.delete('/api/home/CHealth/' + no);
}

export async function deleteAndLoadChildCondtion(home: HomeData, no: number, dateKey: string, bCode: string): Promise<void> {
  await deleteChildCondtion(home, no);
  await loadChildHealth(home, dateKey, bCode);
}

//  --- 報連相
export async function loadInform(home: HomeData, dateKey: string, bCode: string) {
  await axios.get<DInformItem[]>(`/api/home/DInform/${dateKey}`, { params: { bcode: bCode } }).then(response => {
    const informs: DInform[] = response.data.map(v => {
      const inform: DInform = {
        ...v,
        startDate: toDate(new Date(v.startDateText)),
        endDate: toDate(new Date(v.endDateText)),
        editStatus: 0, //< 編集中 0...表示 1...編集中
      };
      return inform;
    });
    home.dInforms = informs;
  });
}

export async function updateInform(inform: DInform) {
  inform.editStatus = 0;
  const item: DInformUpdateItem = {
    ...inform,
    startDateText: inform.startDate.toJSON(),
    endDateText: inform.endDate.toJSON(),
  };
  await axios.put('/api/home/DInform', item);
}

export async function updateAndLoadInform(inform: DInform, home: HomeData, dateKey: string, bCode: string) {
  await updateInform(inform);
  await loadInform(home, dateKey, bCode);
}

export function addInform(home: HomeData, bCode: string): void {
  const no = home.dInforms.map(v => v.no).reduce((acc, crt) => (acc < crt ? acc : crt), 0) - 1;
  const nextNo = no < 0 ? no : -1;
  const def: DInform = {
    no: nextNo,
    bCode: bCode,
    startDate: new Date(),
    endDate: new Date(),
    titleName: '',
    targetCCode: -1,
    targetSCode: -1,
    itemName: '',
    sCode: 0, //< 記述者
    editStatus: 1, //< 編集中
    contents: '',
    makeDateText: toMMddhhmm(new Date()),
  };
  home.dInforms.push(def);
}

export function isValidDInformTitleName(str: string): boolean {
  return isValidLength(str, 6);
}

export function isValidDInformItemName(str: string): boolean {
  return isValidLength(str, 6);
}

export function isValidDInformContents(str: string): boolean {
  return isValidLength(str, 60);
}

export function isValidInform(inform: DInform): boolean {
  const res =
    isValidDInformTitleName(inform.titleName) && isValidDInformItemName(inform.itemName) && isValidDInformContents(inform.contents);
  return res;
}

export async function deleteInform(home: HomeData, no: number): Promise<void> {
  if (no <= 0) {
    home.dInforms = home.dInforms.filter(v => v.no != no);
    return;
  }
  await axios.delete('/api/home/DInform/' + no);
}

export async function deleteAndLoadInform(home: HomeData, no: number, dateKey: string, bCode: string): Promise<void> {
  await deleteInform(home, no);
  await loadInform(home, dateKey, bCode);
}

export const caseOpts: ListOptions = {
  1: '病気',
  2: '怪我',
};

//  --- アレルギー管理(含未食)
export async function loadAllergy(home: HomeData, dateKey: string, bCode: string) {
  await axios.get<CAllergyCheckItem[]>(`/api/home/CAllergy/${dateKey}`, { params: { bcode: bCode } }).then(response => {
    const allergys: CAllergyCheck[] = response.data.map(v => {
      const allergy: CAllergyCheck = {
        ...v,
        checkB: v.check === 1,
      };
      return allergy;
    });
    home.cAllergyCheck = allergys;
  });
}

async function updateAllergy(allergy: CAllergyCheck, dateKey: string, bCode: string) {
  const item: CAllergyCheckUpdateItem = {
    no: allergy.no,
    cCode: allergy.cCode,
    case: allergy.case,
    cMeal: allergy.cMeal,
    foods: allergy.foods,
    check: allergy.checkB ? 1 : 0,
  };
  await axios.put('/api/home/CAllergy/' + dateKey, item, { params: { bcode: bCode } });
}

export async function updateAndLoadAllergy(allergy: CAllergyCheck, home: HomeData, dateKey: string, bCode: string) {
  await updateAllergy(allergy, dateKey, bCode);
  await loadAllergy(home, dateKey, bCode);
}

export const allergyCaseOpts: ListOptions = {
  1: 'アレルギー',
  2: '未食',
};

//  --- event dialog
export function openAddEventDialog(home: HomeData, bCode: string) {
  const minNo = home.dEvents.length > 0 ? home.dEvents.map(v => v.no).reduce((acc, crt) => Math.min(acc, crt), 0) : 0;
  const nextNo = minNo < 0 ? minNo - 1 : -1;
  const dev: DEvent = {
    no: nextNo,
    bCode: bCode,
    itemName: '',
    itemUrl: '',
    targetDateFrom: new Date(),
    targetDateTo: new Date(),
    startDate: new Date(),
    endDate: new Date(),
  };
  home.eventDialog.type = 1;
  home.eventDialog.dEvent = { ...dev };
  home.eventDialog.disp = true;
}

export function openEditEventDialog(home: HomeData, dEvent: DEvent) {
  home.eventDialog.type = 2;
  home.eventDialog.dEvent = { ...dEvent };
  home.eventDialog.disp = true;
}

export function okEventDialog(home: HomeData, dateKey: string, bCode: string) {
  const dev = { ...home.eventDialog.dEvent };
  if (home.eventDialog.type === 1 || home.eventDialog.type === 2) {
    updateAndLoadDEvents(dev, home, dateKey, bCode);
  }
  home.eventDialog.disp = false;
}

export function isValidEventDialog(home: HomeData): boolean {
  const dEvent = home.eventDialog.dEvent;
  return (
    dEvent.itemName.length > 0 &&
    dEvent.itemUrl.length > 0 &&
    dEvent.targetDateFrom <= dEvent.targetDateTo &&
    (!isValidDate(dEvent.endDate) || (isValidDate(dEvent.endDate) && dEvent.startDate <= dEvent.endDate))
  );
}

export function cancelEventDialog(home: HomeData) {
  home.eventDialog.disp = false;
}

//  --- design
export function indexToTimeN(index?: number): string {
  if (index === null || index === undefined) return '-';
  return indexToTime(index);
}

export function toTemperTextN(temp?: number) {
  if (temp === null || temp === undefined) return '-';
  return `${temp} ℃`;
}

export function toValidClassOptStr(str: string): string {
  return str.length === 0 || str === OptNone ? 'ng' : '';
}

export function toValidClassStr(str: string): string {
  return str.length > 0 ? '' : 'ng';
}

export function toStatusMsgChild(fromTo?: number, reason?: string): string {
  if (fromTo === null || fromTo === undefined) return '';
  const resonDisp = reason !== null && reason !== undefined && reason.trim().length > 0;
  return fromToRecordChild[fromTo] + (resonDisp ? ` [${reason}]` : '');
}

//  変更ボタンの表示
export function isDispInOutReason(fromTo?: number): boolean {
  if (fromTo === null || fromTo === undefined) return false;
  return [1, 2, 3, 4, 5, 6].includes(fromTo);
}

const fromToRecordChild: Record<number, string> = {
  0: '',
  1: '未登園',
  2: '確認（登園待ち）',
  3: '確認（当キャ）',
  4: '在園中',
  5: '延長中',
  6: '降園',
};

export function toStatusMsgStaff(fromTo?: number, reason?: string): string {
  if (!fromTo) return '';
  const resonDisp = reason && reason.trim();
  return fromToRecordStaff[fromTo] + (resonDisp ? ` [${reason}]` : '');
}

const fromToRecordStaff: Record<number, string> = {
  0: '',
  1: '未出勤',
  2: '確認（遅刻）',
  3: '確認（欠勤）',
  4: '勤務中',
  5: '残業中',
  6: '退勤',
};

export const childActs: ListOptions = {
  2: '確認(登園待ち)',
  3: '確認(当キャ)',
};

export const staffActs: ListOptions = {
  2: '確認(遅刻)',
  3: '確認(欠勤)',
};

export const weatherList: string[] = ['晴', '曇', '雨', '雨のち晴'];
export function indoorTemps(): string[] {
  return [...Array(40)].map((_, index) => (10 + 0.5 * index).toFixed(1));
}
export function humidities(): string[] {
  return [...Array(41)].map((_, index) => (0 + 5 * index).toFixed(1));
}
export function wbgts(): string[] {
  return [...Array(101)].map((_, index) => index.toFixed(1));
}

export function toTemperText(temper?: string): string {
  if (temper === null) return '---';
  return `${temper} ℃`;
}

export function toDInformName(code: number, listOpts: ListOptions): string {
  if (code < 0) return 'なし';
  if (code === 0) return '全員';
  return code in listOpts ? listOpts[code] : 'なし';
}

//  Title Conent のみ
export async function saveMemoChild(child: ChildInOut, home: HomeData, dateKey: string, bCode: string): Promise<void> {
  const item: CMemoItem = {
    memoId: child.memoId,
    cCode: child.cCode,
    title: child.memoTitle,
    content: child.memoContent,
  };
  await axios.put('/api/home/CMemo/' + dateKey, item);
  await loadChildInOut(home, dateKey, bCode);
}

//  Status のみ
export async function saveMemoStatusChild(child: ChildInOut, home: HomeData, dateKey: string, bCode: string): Promise<void> {
  const item: CMemoItem = {
    memoId: child.memoId,
    cCode: child.cCode,
    status: child.memoStatus,
  };
  await axios.put('/api/home/CMemo/' + dateKey, item);
  await loadChildInOut(home, dateKey, bCode);
}

export async function saveMemoStaff(staff: StaffInOut, home: HomeData, dateKey: string, bCode: string): Promise<void> {
  const item: SMemoItem = {
    memoId: staff.memoId,
    sCode: staff.sCode,
    title: staff.memoTitle,
    content: staff.memoContent,
  };
  await axios.put('/api/home/SMemo/' + dateKey, item);
  await loadStaffInOut(home, dateKey, bCode);
}

export async function saveMemoStatusStaff(staff: StaffInOut, home: HomeData, dateKey: string, bCode: string): Promise<void> {
  const item: SMemoItem = {
    memoId: staff.memoId,
    sCode: staff.sCode,
    status: staff.memoStatus,
  };
  await axios.put('/api/home/SMemo/' + dateKey, item);
  await loadStaffInOut(home, dateKey, bCode);
}

export async function save(home: HomeData, date: Date, bCode: string) {
  const item: MovementUpdateItem = {
    dDailyItem: {
      ...home.movement.dDaily,
      targetDateText: toDateKey(date),
      todayReader: home.basic.sssStaffOpts.find(v => v.sCode === home.movement.dDaily.todayReaderSCode)?.sName ?? '',
    },
    dAlarmItems: home.movement.dAlarms.map(v => {
      const dAlarmItem: DAlarmItem = {
        ...v,
        targetDateText: toDateKey(date),
      };
      return dAlarmItem;
    }),
  };
  await axios.put('/api/home/movement/' + toDateKey(date), item, {
    params: {
      bCode: bCode,
    },
  });
  home.editedMovement = false;
  loadBase(home, toDateKey(date), bCode);
}

/* 警報 */
export function addAlarm(home: HomeData, date: Date, bCode: string): void {
  const no = home.movement.dAlarms.map(v => v.no).reduce((acc, crt) => (acc < crt ? acc : crt), 0) - 1;
  const nextNo = no < 0 ? no : -1;
  const def: DAlarm = {
    no: nextNo,
    targetDate: date,
    bCode: bCode,
    alarmName: '',
    stTimeText: '',
    edTimeText: '',
    editStatus: 1,
  };
  home.movement.dAlarms.push(def);
}

export function deleteAlarm(home: HomeData, no: number) {
  home.movement.dAlarms = home.movement.dAlarms.filter(v => v.no != no);
}

export function toStrings(rows: PulldownListRow[]): string[] {
  return rows.map(v => v.pCcontents);
}

//  食事予約の編集が可能か
export function isEditableScheduleMeal(childInOut: ChildInOut, meal: number, targetDate: Date): boolean | undefined {
  if (meal >= eatKomas.length) return undefined;

  const now = new Date();
  if (toDate(targetDate).getTime() < toDate(now).getTime()) return false; //< 今日の時点で過ぎている
  if (toDate(targetDate).getTime() > toDate(now).getTime()) {
    //  未来 ※予定内の食事のみ編集可能
    const targetKoma = eatKomas[meal];
    if (!childInOut.fromKoma || !childInOut.toKoma) return false;
    return childInOut.fromKoma <= targetKoma && targetKoma < childInOut.toKoma;
  } else {
    //  以下、今日
    const nowKoma = now.getHours() * 4 + Math.floor(now.getMinutes() / 15);
    const targetKoma = eatKomas[meal];
    if (targetKoma < nowKoma) return false; //< 今日の予定変更可能な時間を過ぎている

    //  予定内のみ編集可能
    if (!childInOut.fromKoma || !childInOut.toKoma) return false;
    return childInOut.fromKoma <= targetKoma && targetKoma < childInOut.toKoma;
  }
}

//  食事結果の編集が可能か
export function isEditableResultMeal(childInOut: ChildInOut, meal: number, targetDate: Date): boolean | undefined {
  if (meal >= eatKomas.length) return undefined;

  const now = new Date();
  if (toDate(targetDate).getTime() < toDate(now).getTime()) return true; //< 今は今日より前
  if (toDate(targetDate).getTime() > toDate(now).getTime()) return false; //< 今は今日より後

  //  以下、今日
  const nowKoma = now.getHours() * 4 + Math.floor(now.getMinutes() / 15);
  const targetKoma = eatKomas[meal];
  if (targetKoma >= nowKoma) return false; //< 今日の予定変更可能な時間を過ぎている

  if (!childInOut.fromKoma || !childInOut.toKoma) return false;
  return childInOut.fromKoma <= targetKoma && targetKoma < childInOut.toKoma;
}

export function toggleScheduleMeal(childInOut: ChildInOut, index: number) {
  const crt = childInOut.scheduleMeals[index]; //< 0... 未設定 / 1...予定あり / ２...予定解除
  childInOut.scheduleMeals[index] = crt === 0 || crt === 2 ? 1 : 2;
}

export function incResultMeal(childInOut: ChildInOut, index: number) {
  childInOut.resultMeals[index] = (childInOut.resultMeals[index] + 1) % 3;
}

export async function updateScheduleMeal(
  home: HomeData,
  cCode: number,
  cMeal: number,
  cMealReserve: number,
  bCode: string,
  targetDate: Date
) {
  const item: EatScheduleUpdateItem = {
    cCode: cCode,
    bCode: bCode,
    cMeal: cMeal,
    cMealReserve: cMealReserve,
  };
  const dateKey = toDateKey(targetDate);
  await axios.put('/api/home/CEatSchedule/' + dateKey, item).then(async () => await loadChildInOut(home, dateKey, bCode));
}

export async function updateResultMeal(home: HomeData, cCode: number, cMeal: number, cMealEat: number, bCode: string, targetDate: Date) {
  const item: EatResultUpdateItem = {
    cCode: cCode,
    bCode: bCode,
    cMeal: cMeal,
    cMealEat: cMealEat,
  };
  const dateKey = toDateKey(targetDate);
  await axios.put('/api/home/CEatResult/' + dateKey, item).then(async () => await loadChildInOut(home, dateKey, bCode));
}

export function isOfficeBase(home: HomeData, bCode: string): boolean {
  if (!home.basic.baseOptions) return false;
  const opts: Record<string, BaseOption> = home.basic.baseOptions;
  return opts[bCode].office;
}

export async function onMaintMStaff() {
  const ask = await checkMaintMTable('mstaff');
  ask.unshift('MStaff　更新内容');
  ask.push('更新しますか');
  const yn = await yesno(ask.join('\n'));
  if (yn) {
    maintMTable('mstaff');
  }
}

export async function onMaintMChild() {
  const ask = await checkMaintMTable('mchild');
  ask.unshift('MChild　更新内容');
  ask.push('更新しますか');
  const yn = await yesno(ask.join('\n'));
  if (yn) {
    maintMTable('mchild');
  }
}

export async function onMaintC2Schedule() {
  const ask = await checkMaintMTable('c2schedule');
  ask.unshift('C2Schedule　更新内容');
  ask.push('更新しますか');
  const yn = await yesno(ask.join('\n'));
  if (yn) {
    maintMTable('c2schedule');
  }
}

export async function onMaintC3Result() {
  const ask = await checkMaintMTable('c3result');
  ask.unshift('C3result　更新内容');
  ask.push('更新しますか');
  const yn = await yesno(ask.join('\n'));
  if (yn) {
    maintMTable('c3result');
  }
}

export async function onMaintMStaffDailyMemo() {
  const ask = await checkMaintMTable('mstaffdailymemo');
  ask.unshift('mstaffdailymemo　更新内容');
  ask.push('更新しますか');
  const yn = await yesno(ask.join('\n'));
  if (yn) {
    maintMTable('mstaffdailymemo');
  }
}

export function toMemoTitle(mTitleOpts: ListOptionNum[], memoTitle: number): string {
  return memoTitle > 0 ? mTitleOpts.find(v => v.value === memoTitle)?.text ?? '' : '';
}

export function toCategoryText(cateOpts: ListOptionNum[], cate: number | undefined): string {
  return cate === undefined ? '[ - ]' : `[ ${cateOpts.find(v => v.value === cate)?.text ?? '-'} ]`;
}

//  状況に応じた背景色を指定するクラス名の取得
export function toBackStatusId(
  fromto: number | undefined,
  title: number,
  preInTitles: readonly number[],
  cancelTitles: readonly number[]
): number {
  if (fromto === EFromTo.none || fromto === EFromTo.noIn) {
    return preInTitles.find(v => v === title) ? EFromTo.preIn : cancelTitles.find(v => v === title) ? EFromTo.cancel : fromto;
  } else {
    return fromto ?? EFromTo.none;
  }
}
